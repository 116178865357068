<template>
  <v-sheet
      :color="color"
      :width="width"
      :min-width="width !== '100%' ? width : null"
      :max-width="width !== '100%' ? width : null"
      :height="height"
      v-intersect="intersector"
      ref="imageContainer"
      class="overflow-hidden"
  >
    <v-fade-transition>
      <img
          @load="imageLoad"
          v-if="src && display"
          :src="sizeSrc"
          :alt="alt"
          width="100%"
          :height="height ? '100%' : 'auto'"
          :style="imgStyle"

      >
    </v-fade-transition>

  </v-sheet>
</template>

<script>
export default {
  name: "wsImg",
  props : {
    color : {
      type : String,
      default : 'transparent'
    },
    width : {
      type : [String, Number],
      default : '100%'
    },
    height : {
      type : String,
    },
    src : {
      type : String
    },
    alt : {
      type : String,
      default : 'image'
    },
    sm : {
      type : Boolean,
      default : false
    },
    md : {
      type : Boolean,
      default : false
    },
    xl : {
      type : Boolean,
      default : false
    },
    autoSize : {
      type : Boolean,
      default : false
    },
    contain : {
      type : Boolean,
      default : false
    },
    verticalPosition : {
      type : [ Number , String ],
      default : 'center'
    },
    horizontalPosition : {
      type : [ Number , String ],
      default : 'center'
    },
    imageStyle : {
      type : String,
      default : ''
    },
    triggerResize : {
      type : Number,
      default : 0
    },
    aspectRatio : {
      type : Number
    }
  },
  data() {
    return {
      imgCalc : 0,
      display : false,
      imageReady : false,
      intersected : false,
      backgroundColor : '#ffffff',
      sizes : {
        xl :1200,
        l :900,
        m :600,
        sm :300 ,
        xs : 100
      },
      sizesArray : [ 100 , 300 , 600 , 900 , 1200 ],
      imageSize : null,

    }
  },
  computed : {
    sizeSrc() {
      if ( !this.autoSize ) {
        return this.src
      }



      if ( this.src.includes('cloud') && this.src.includes('westudy.ua') ) {
        return this.src + '&q=' + this.imageSize
      }

      if (   this.src.includes('images.unsplash.com') && this.src.includes('w=1080') ) {
        let url = this.src.slice(0, -6);
        url = url + 'w=' + this.imageSize
        return url
      }

      return this.src

    },
    cWidth() {
      if ( !this.$refs.imageContainer ) {
        return 'null'
      }
      let container = this.$refs.imageContainer.$el
      if ( !container ) {
        return 'null'
      }
      let rect = container.getBoundingClientRect()
      let width = rect.width

      return width

    },
    imgStyle() {
      let style = `transition : opacity 0.3s ease;`
      let verticalPosition = 'center'
      let horizontalPosition = 'center'

      style += `opacity : ${this.imageReady ? 1 : 0};`
      style += `object-fit : ${this.contain ? 'contain' : 'cover'} ;`

      if ( this.verticalPosition !== 'center' ) {
        verticalPosition = this.verticalPosition + '%'
      }
      if ( this.horizontalPosition !== 'center' ) {
        horizontalPosition = this.horizontalPosition + '%'
      }

      style += `object-position : ${horizontalPosition} ${verticalPosition} ;`

      if ( this.imageStyle ) {
        style += this.imageStyle + ';'
      }

      return style
    }
  },
  watch : {
    triggerResize() {
      this.calculateImageSize()
    },
    src() {
      if ( !this.intersected ) {
        return
      }
      this.display = false
      this.imageReady = false
      setTimeout(()=>{
        this.prepareImage()
      },1)
    },
    WINDOW_WIDTH() {
      this.calculateImageSize()
    },
    intersected(value) {
      if ( value ) {
        this.prepareImage()
      }
    }
  },
  methods : {
    calculateImageSize() {

      if ( !this.autoSize ) {
        return
      }

      if ( !( (this.src.includes('cloud') && this.src.includes('westudy.ua')) || this.src.includes('images.unsplash.com') )  ) {
        return
      }



      let container = this.$refs.imageContainer.$el
      let rect = container.getBoundingClientRect()
      let width = rect.width
      this.imgCalc = width

      let size = this.sizesArray.filter(el => el > width)
      if ( size.length > 0 ) {
        if ( !this.imageSize ) {
          this.imageSize = size[0]
        } else if ( size[0] > this.imageSize ) {
          this.imageSize = size[0]
        }

      }
    },
    prepareImage() {
      this.calculateImageSize()
      this.display = true
    },
    imageLoad() {
      if ( !this.imageReady) {
        this.imageReady = true
      }
    },
    intersector(entries) {
      if ( !entries ) {
        return
      }
      if (entries[0]) {
        if (entries[0].isIntersecting && entries[0].boundingClientRect.y > 0) {
          if ( !this.intersected ) {
            this.intersected = true
          }
        }
      }
    }
  },
}
</script>

<style scoped>

</style>